import { IPostsListPgae } from "../../utils/BrandedCommunity/PostsListContainer/usePostsListContainer.types";

export const POSTS_TABS_KEYS = {
  FEED: 1,
  PINNED_POSTS: 2,
};

export const POSTS_TABS = {
  [POSTS_TABS_KEYS.FEED]: { label: "Feed", value: POSTS_TABS_KEYS.FEED },
  [POSTS_TABS_KEYS.PINNED_POSTS]: {
    label: "Pinned posts",
    value: POSTS_TABS_KEYS.PINNED_POSTS,
  },
};
export const POSTS_TABS_LIST = [
  POSTS_TABS[POSTS_TABS_KEYS.FEED],
  POSTS_TABS[POSTS_TABS_KEYS.PINNED_POSTS],
];

export const POSTS_LIST_PAGE_DEFAULT_DATA: IPostsListPgae = {
  total_pages: 0,
  posts: [],
  total: 0,
};
