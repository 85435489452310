import { appendURLSearchParams } from "../../../../../../../utils/common/url";
import { LINK_PREVIEW_URLS } from "../LinkPreview.constants";
import { ILinkPreviewMetadata } from "../LinkPreview.interfaces";

export const fetchWebpageMetadata = ({
  url,
  apiKey,
  onSuccess,
  onError,
  onFinally,
}: {
  url: string;
  apiKey?: string;
  onSuccess?: (metadata: ILinkPreviewMetadata) => void;
  onError?: (error?: string) => void;
  onFinally?: () => void;
}) => {
  if (!url) return;

  if (apiKey) {
    const request = fetch(
      appendURLSearchParams(LINK_PREVIEW_URLS.get_preview_data, {
        api_key: apiKey,
        url,
      }),
    );

    if (onSuccess)
      request
        .then((data: any) => data.json() as Promise<ILinkPreviewMetadata>)
        .then((data) => {
          if (data.error) throw new Error(data.error);
          onSuccess(data);
        });
    if (onError) request.catch(onError);
    if (onFinally) request.finally(onFinally);
  } else if (onError) {
    onError("Please provide API key.");
  }
};
