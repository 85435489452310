import { getStylesObjFromCss } from "../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      padding: "0px 18px 18px",
      display: "flex",
      flexDirection: "column",
    },
  },
  componentName: "MembersPanelList",
});
