import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
      gap: "8px",
    },
    textArea: {
      flex: "1",
      resize: "none",
      border: "0",
      outline: "0",
      padding: "0",
      fontFamily: "inherit",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      display: "block",
    },
  },
  componentName: "TextAreaWithAutoHeight",
});
