import React, { FC } from "react";

import MembersPanelHeader from "./components/Header/MembersPanelHeader";
import MembersList from "./components/MembersList/MembersList";
import Status from "./components/Status/Status";

import { classNames } from "../../../utils/common/styling";

import { IMembersPanel } from "./IMembersPanel";
import { styles } from "./MembersPanel.styles";
import { DEFAULT_CREATOR_THEME } from "../../../features/Common/modules/Theme/constants/Theme.constants";
import { CreatorThemeContext } from "../../../features/Common/modules/Theme/CreatorThemeContext";

const MembersPanel: FC<IMembersPanel.IProps> = ({
  theme = DEFAULT_CREATOR_THEME,
  className,
  headerProps,
  statusProps,
  memberListProps,
}) => {
  return (
    <CreatorThemeContext.Provider value={theme}>
      <div className={classNames(styles.root, className)}>
        <MembersPanelHeader {...headerProps} />

        <Status {...statusProps} />

        <MembersList {...memberListProps} />
      </div>
    </CreatorThemeContext.Provider>
  );
};

export default MembersPanel;
