import React, { useEffect, useRef } from "react";
import { classNames } from "../../../../utils/common/styling";
import { styles } from "./TextAreaWithAutoHeight.styles";
import { ITextAreaWithAutoHeightProps } from "./TextAreaWithAutoHeight.types";
import { checkIsFunction } from "../../../../utils/common/dataTypes/function";
import { setHeightOfTextArea } from "../../../../utils/common/FormInputs/TextArea/TextAreaWithAutoHeight";

const TextAreaWithAutoHeight = ({
  onChange,
  classes = {},
  overrideClasses = {},
  defaultValue,
  inputRef,
  extras,
  ...rest
}: ITextAreaWithAutoHeightProps) => {
  const localRef = useRef<HTMLTextAreaElement>();
  useEffect(() => {
    /**
     * Due to improper size change function without using resizeObserver in webapp side it was
     * taking random size by default so the proper way of doing it is with resizeObserver hook by js
     * */
    const observer = new ResizeObserver(() => {
      if (localRef?.current) {
        setHeightOfTextArea(localRef.current);
      }
    });

    /**
     * Assiging the localRef to start observing the change in size of height of TextArea.
     */
    if (localRef?.current) {
      observer.observe(localRef.current);
    }

    setTimeout(() => {
      // This bring cursor to the end of the input text on mount of the component.
      // This is helpful when `autoFocus` prop is passed as true to this component.
      if (localRef?.current) {
        localRef.current.selectionStart = localRef.current.selectionEnd = `${
          defaultValue || ""
        }`.length;
      }
    }, 0);

    /**
     * On Component UnMount removing the resizeObserver to clear unnecessary
     * computations and observation by resizeObserver.
     */
    return () => {
      if (localRef.current) {
        observer.unobserve(localRef.current);
      }
    };
  }, [localRef]);

  return (
    <div
      className={overrideClasses.root || classNames(styles.root, classes.root)}
    >
      <textarea
        ref={(element) => {
          // why this is done - https://stackoverflow.com/a/65877297
          if (localRef) {
            localRef.current = element ?? undefined;
          }

          if (typeof inputRef === "function") {
            inputRef(element);
          } else if (inputRef) {
            inputRef.current = element;
          }
        }}
        className={
          overrideClasses.textArea ||
          classNames(styles.textArea, classes.textArea)
        }
        onChange={(e) => {
          setHeightOfTextArea(e.target);

          if (checkIsFunction(onChange)) {
            onChange!(e);
          }
        }}
        defaultValue={defaultValue}
        {...rest}
      />
      {extras}
    </div>
  );
};

export default TextAreaWithAutoHeight;
