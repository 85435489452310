import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      borderRadius: "8px",
      backgroundColor: "white",
      maxHeight: "calc(100vh - 50px)",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
  },
  componentName: "ModalContent",
});
