import React, { Suspense } from "react";
import { IPostVideoProps } from "./PostVideo.types";
import { evaluateHidePostVideo } from "../../../utils/BrandedCommunity/PostVideo/evaluateHidePostVideo";

const PostVideo = React.lazy(() => import("./PostVideo"));

const LazyLoadedPostVideo = (props: IPostVideoProps) => {
  const { showVideo, videoUrl } = props;
  if (evaluateHidePostVideo({ showVideo, videoUrl })) return null;
  return (
    <Suspense fallback={null}>
      <PostVideo {...props} />
    </Suspense>
  );
};

export default LazyLoadedPostVideo;
