import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    modalFooter: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderTop: "2px solid #E7E6E4",
      padding: "20px 24px",
    },
  },
  componentName: "ModalFooter",
});
