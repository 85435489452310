import React from "react";
import { styles } from "./PinnedStatusIcon.styles";
import { IPostProps } from "../../Post.types";

const PinnedStatusIcon = ({
  isPinned,
  onClick,
}: {
  isPinned: IPostProps["data"]["is_pinned"];
  onClick: React.DOMAttributes<SVGSVGElement>["onClick"];
}) => {
  const commonProps = {
    onClick,
    className: styles.root,
  };
  return isPinned ? (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...commonProps}
    >
      <mask
        id="mask0_1781_8797"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="22"
      >
        <rect width="22" height="22" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1781_8797)">
        <path
          d="M14.6667 11L16.5 12.8333V14.6667H11.9167V20.1667L11 21.0833L10.0833 20.1667V14.6667H5.5V12.8333L7.33333 11V4.58333H6.41667V2.75H15.5833V4.58333H14.6667V11Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  ) : (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...commonProps}
    >
      <mask
        id="mask0_1781_26321"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="22"
      >
        <rect width="22" height="22" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1781_26321)">
        <path
          d="M14.6667 11L16.5 12.8333V14.6667H11.9167V20.1667L11 21.0833L10.0833 20.1667V14.6667H5.5V12.8333L7.33333 11V4.58333H6.41667V2.75H15.5833V4.58333H14.6667V11ZM8.1125 12.8333H13.8875L12.8333 11.7792V4.58333H9.16667V11.7792L8.1125 12.8333Z"
          fill="#8A8A8A"
        />
      </g>
    </svg>
  );
};

export default PinnedStatusIcon;
