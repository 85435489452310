import React from "react";
import { IExlyImageProps } from "./ExlyImage.interfaces";
import { getOptimisedImageUrl } from "./utils/getOptimisedImageUrl";

const ExlyImage = ({
  src = "",
  fetchWidth,
  cdnBaseUrl,
  loading = "lazy",
  ...rest
}: IExlyImageProps) => {
  /**
   * Key attribute is important in order to make the src changed load instantly.
   * https://stackoverflow.com/questions/50690956/react-img-not-immediately-changing-when-src-changes-how-can-i-fix-this
   */
  const processedImgSrc = getOptimisedImageUrl({
    cdnBaseUrl: cdnBaseUrl,
    fetchWidth,
    imageUrl: src,
  });
  return (
    <img
      key={processedImgSrc}
      src={processedImgSrc}
      loading={loading}
      {...rest}
    />
  );
};

export default ExlyImage;
