import React from "react";
import { classNames } from "../../../../utils/common/styling";
import { styles } from "./ModalFooter.styles";
import { IModalFooterProps } from "./ModalFooter.types";

const ModalFooter = ({ className = "", children }: IModalFooterProps) => {
  return (
    <div className={classNames(styles.modalFooter, className)}>{children}</div>
  );
};

export default ModalFooter;
