import React, { Suspense } from "react";
import { ICommentSectionProps } from "./CommentSection.types";

const CommentSection = React.lazy(() => import("./CommentSection"));

export type ILazyLoadedCommentSectionProps = ICommentSectionProps & {
  show?: boolean;
  commentSectionStartRef: React.MutableRefObject<HTMLDivElement | null>;
};

const LazyLoadedCommentSection = ({
  show,
  commentSectionStartRef,
  ...props
}: ILazyLoadedCommentSectionProps) => {
  const componentStart = <div ref={commentSectionStartRef} />;
  if (!show) return componentStart;
  return (
    <>
      {componentStart}
      <Suspense fallback={null}>
        <CommentSection {...props} />
      </Suspense>
    </>
  );
};

export default LazyLoadedCommentSection;
