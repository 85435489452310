import React from "react";
import { IButtonProps } from "./Button.types";
import { styles } from "./Button.styles";
import { classNames } from "../../../utils/common/styling";
import MUIButton from "@mui/material/ButtonBase";

const Button = ({
  children,
  color = "primary",
  size = "medium",
  borderRadius = "rounded",
  className,
  ...rest
}: IButtonProps) => {
  return (
    <MUIButton
      classes={{
        root: classNames(
          className,
          styles.btn,
          styles[`btn-color-${color}`],
          styles[`btn-size-${size}`],
          styles[`btn-borderRadius-${borderRadius}`],
        ),
      }}
      {...rest}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
