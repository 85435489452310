import React, { Suspense } from "react";
import { ILikedByMembersListModalProps } from "./LikedByMembersListModal.types";

const LikedByMembersListModal = React.lazy(
  () => import("./LikedByMembersListModal"),
);

const LazyLoadedLikedByMembersListModal = (
  props: ILikedByMembersListModalProps,
) => {
  const { isOpen } = props;
  if (!isOpen) return null;
  return (
    <Suspense fallback={null}>
      <LikedByMembersListModal {...props} />
    </Suspense>
  );
};

export default LazyLoadedLikedByMembersListModal;
