import React, { FC } from "react";

import { Button } from "@mui/material";

import AddIcon from "@mui/icons-material/AddRounded";

import { classNames } from "../../../../utils/common/styling";

import { ISidebarCta } from "./ISidebarCta";
import { getThemedStyles, styles } from "./SidebarCta.styles";
import { useCreatorThemeContext } from "../../../../features/Common/modules/Theme/CreatorThemeContext";

const SidebarCta: FC<ISidebarCta.IProps> = ({
  className,
  classes,
  overrideClasses = {},
  label,
  ctaVariant = "primary",
  ...restButtonProps
}) => {
  const { root, startIcon } = classes || {};
  const theme = useCreatorThemeContext();

  const themedStyles = getThemedStyles({ theme });

  const ctaClasses = {
    primary: {
      root: themedStyles.root,
      startIcon: themedStyles.startIcon,
    },
    secondary: {
      root: styles.secondaryRoot,
      startIcon:
        overrideClasses.secondaryStartIcon || styles.secondaryStartIcon,
    },
  };

  return (
    <div className={classNames(styles.wrapperRoot, className)}>
      <Button
        classes={{
          root: classNames(styles.baseRoot, ctaClasses[ctaVariant].root, root),
          startIcon: classNames(
            styles.baseStartIcon,
            ctaClasses[ctaVariant].startIcon,
            startIcon,
          ),
        }}
        startIcon={<AddIcon />}
        {...restButtonProps}
      >
        {label}
      </Button>
    </div>
  );
};

export default SidebarCta;
