import React, { FC } from "react";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import Collection from "./Collection/Collection";
import { ICollections } from "./ICollections";
import { reorder } from "./Collections.utils";

const Collections: FC<ICollections.IProps> = ({
  showActions,
  collections,
  value,
  expandedCollections,

  onChange,
  onClick,
  onClickChannel,
  onEdit,
  onAddChannel,
}) => {
  const expandedCollectionsSet = new Set(expandedCollections);

  const handleDragEnd = (result: {
    destination?: { index: number };
    source: { index: number };
  }) => {
    if (!result.destination || result.destination.index === -1) return;

    const updatedCollections = reorder(
      collections,
      result.source.index,
      result.destination.index,
    );

    if (onChange) onChange(updatedCollections);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {collections?.map((collection, index) => (
              <Collection
                key={collection.id}
                selectedChannelId={value}
                index={index}
                expanded={expandedCollectionsSet.has(collection.id)}
                showActions={showActions}
                showDivider={index < collections.length - 1}
                collection={collection}
                onClick={onClick}
                onClickChannel={onClickChannel}
                onEdit={onEdit}
                onAddChannel={onAddChannel}
              />
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Collections;
