import { getStylesObjFromCss } from "../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    titleDangerIcon: {
      marginRight: "8px",
    },
    modalBody: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    bodyDangerIcon: {
      fontSize: "4rem !important",
      marginBottom: "8px",
    },
    modalFooter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "30px",
    },
    primaryButton: {
      backgroundColor: "#BF2600 !important",
      color: "white !important",
    },
  },
  componentName: "DeleteConfirmationModal",
});
