import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { classNames } from "../../../../utils/common/styling";
import { styles } from "./ModalHeader.styles";
import { IModalHeaderProps } from "./ModalHeader.types";

const ModalHeader = ({
  classes = {},
  children,
  hideCloseIcon,
  onClose,
}: IModalHeaderProps) => {
  return (
    <div className={classNames(styles.root, classes.root)}>
      <div className={classNames(styles.container, classes.container)}>
        {children}
        {!hideCloseIcon ? (
          <CloseRoundedIcon
            className={classNames(styles.close, classes.close)}
            onClick={onClose}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ModalHeader;
