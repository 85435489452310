import LinkIcon from "@mui/icons-material/Link";
import React, { FC } from "react";
import useLinkPreview from "../../../../../features/Common/modules/Urls&Routing/modules/LinkPreview/useLinkPreview";
import { checkIsObjectEmpty } from "../../../../../utils/common/dataTypes/object";
import { styles } from "./LinkPreview.styles";
import { ILinkPreview } from "./types/ILinkPreview";
import { getPreviewImage } from "../../../../../features/Offerings/modules/BrandedCommunity/components/LinkPreview/utils/LinkPreview.utils";

const LinkPreview: FC<ILinkPreview> = ({ url, apiKey }) => {
  const { isLoading, previewData } = useLinkPreview({ url, apiKey });

  if (checkIsObjectEmpty(previewData) || isLoading) return null;

  const { images, favicon, domain, title, description } = previewData ?? {};
  const [image] = images ?? [];
  const previewImage = getPreviewImage(image);

  return (
    <a className={styles.root} href={url} target="_blank" rel="noreferrer">
      <div className={styles.thumbnailContainer}>
        {previewImage ? (
          <img className={styles.thumbnail} src={previewImage} alt="" />
        ) : (
          <LinkIcon />
        )}
      </div>

      <div className={styles.dataContainer}>
        <div>
          {title ? (
            <div className={styles.title} title={title}>
              <span>{title}</span>
            </div>
          ) : null}
          {description ? (
            <div className={styles.description} title={description}>
              <span>{description}</span>
            </div>
          ) : null}
        </div>

        {favicon || domain ? (
          <div className={styles.iconContainer} title={url}>
            {favicon ? <img src={favicon} alt="" /> : null}
            {domain ? <div>{domain}</div> : null}
          </div>
        ) : null}
      </div>
    </a>
  );
};

export default LinkPreview;
