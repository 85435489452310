export const QUESTION_TYPES = {
  TEXT_QUESTION: 1,
  SINGLE_SELECT: 2,
  MULTI_SELECT: 3,
  CALENDAR: 4,
  FILE: 5,
  STAR_RATING: 6,
  FIVE_NUMBER_RATING: 7,
  TEN_NUMBER_RATING: 8,
};

export const EXAM_SCORING_TYPES = {
  AUTO: 1,
  MANUAL: 2,
};

export const EXAM_STATUS = {
  PASSED: 1,
  FAILED: 2,
  PENDING_EVALUATION: 3,
};
export const QUESTIONNAIRE_TYPES = {
  EXAM: 1,
  FORM: 2,
};

export const QUESTIONNAIRE_SUBTEXT = {
  START_EXAM_SUBTEXT:
    "Feeling ready for a challenge? Let’s jog your memory in this next assessment!",
  START_FORM_SUBTEXT: "Please fill this form before you proceed.",
  FORM_COMPLETED_SUBTEXT: "Thanks for filling the form.",
};
