export const images_format_keys = {
  avif: "avif",
  heic: "heic",
  heif: "heif",
  jpeg: "jpeg",
  jpg: "jpg",
  png: "png",
  webp: "webp",
  gif: "gif",
  svg: "svg",
  tiff: "tiff",
} as const;

export const file_mime_types = {
  [images_format_keys.avif]: ["image/avif"],
  [images_format_keys.heic]: ["image/heic"],
  [images_format_keys.heif]: ["image/heif"],
  [images_format_keys.jpeg]: ["image/jpeg"],
  [images_format_keys.jpg]: ["image/jpeg"],
  [images_format_keys.png]: ["image/png"],
  [images_format_keys.webp]: ["image/webp"],
  [images_format_keys.gif]: ["image/gif"],
  [images_format_keys.svg]: ["image/svg+xml"],
  [images_format_keys.tiff]: ["image/tiff"],
};
