import { ICollection } from "./Collection/ICollection";

export const reorder = (
  list: ICollection.ICollectionProps[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
