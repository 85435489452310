import React from "react";
import { styles } from "./ModalBody.styles";
import { classNames } from "../../../../utils/common/styling";
import { IModalBodyProps } from "./ModalBody.types";

const ModalBody = ({ children, className, ...rest }: IModalBodyProps) => {
  return (
    <div className={classNames(styles.root, className)} {...rest}>
      {children}
    </div>
  );
};

export default ModalBody;
