import { parseISO, isToday, parse, isBefore } from "date-fns";

/**
 * Checks if a given date string represents today's date.
 * @param {string} dateString - The date string to check.
 * @returns {boolean} Returns true if the date string represents today's date, false otherwise.
 */

export const isDateToday = (dateString) => {
  if (!dateString) return false;

  const date = parseISO(dateString);

  if (!date) return false;

  return isToday(date);
};

/**
 * Checks if a given date string represents a past time based on the provided format.
 * @param {Object} params - The parameters object.
 * @param {string} params.dateString - The date string to check.
 * @param {string} params.format - The format of the date string.
 * @returns {boolean} Returns true if the date string represents a past time, false otherwise.
 */
export const isPastTime = ({ dateString, format }) => {
  const parsedDate = parse(dateString, format, new Date());
  const isPast = isBefore(parsedDate, new Date());
  return isPast;
};
