import React, { FC } from "react";
import SectionCTA from "../SectionCTA/SectionCTA";
import { ISectionCTAsGroup } from "./ISectionCTAsGroup";

const SectionCTAsGroup: FC<ISectionCTAsGroup.IProps> = ({
  value,
  sections,
  ctaProps,
  onClick,
  showActions,
  onEdit,
  className,
}) => {
  return (
    <div className={className}>
      {sections?.map((section, index) => {
        const { label, icon, id, hide } = section;
        if (hide) return null;
        return (
          <SectionCTA
            key={id}
            label={label}
            icon={icon}
            active={value === id}
            onClick={(e) => onClick?.({ index, section, event: e })}
            showActions={showActions}
            onEdit={(e) => onEdit?.({ index, section, event: e })}
            {...ctaProps}
          />
        );
      })}
    </div>
  );
};

export default SectionCTAsGroup;
