import { MOBILE_SCREEN_WIDTH } from "../../../../constants/common/screen";
import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      borderBottom: "2px solid #E7E6E4",
      [`@media(max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        padding: "18px 16px",
      },
      [`@media(min-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        padding: "20px 24px",
      },
    },

    container: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      gap: "10px",
      fontSize: "18px",
      fontWeight: "700",
    },

    close: {
      transition: "0.1s ease-in all !important",
      cursor: "pointer",
      marginRight: "-5px",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  },
  componentName: "ModalHeader",
});
