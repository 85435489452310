export const EXLY_COMMUNITY_FEED_TYPES = {
  COMMUNITY_CHANNEL: 1,
  COMMUNITY: 2,
  // BLOG: 3, // not used
};

export const EXLY_COMMUNITY_CHANNEL_TYPES = {
  GROUP_CHAT: 1,
  POST: 2,
  DIRECT_CHAT: 3,
};
