import { getStylesObjFromCss } from "../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      cursor: "pointer",
    },
  },
  componentName: "PinnedStatusIcon",
});
