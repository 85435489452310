import React from "react";

export const POST_TYPE_IDS_ENUM = {
  TEXT: 1,
  IMAGE: 2,
  VIDEO: 3,
  OFFERING: 4,
  DOCUMENT: 5,
};

export const DEFAULT_POST_TYPE_ID = POST_TYPE_IDS_ENUM.TEXT;

export const LINK_PREVIEWS = {
  RETRY_COUNT: 5,
  RETRY_INTERVAL: 2000,
};

export const POST_TYPE_DETAILS_MAP = {
  // [post_types_map.TEXT]: {}, // Text is not shown as an option in UI
  [POST_TYPE_IDS_ENUM.IMAGE]: {
    label: "Image",
    id: POST_TYPE_IDS_ENUM.IMAGE,
    svgIcon: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.83333 12.1667H12.1667C12.3333 12.1667 12.4583 12.0903 12.5417 11.9375C12.625 11.7847 12.6111 11.6389 12.5 11.5L10.2083 8.4375C10.125 8.32639 10.0139 8.27083 9.875 8.27083C9.73611 8.27083 9.625 8.32639 9.54167 8.4375L7.375 11.3333L5.83333 9.27083C5.75 9.15972 5.63889 9.10417 5.5 9.10417C5.36111 9.10417 5.25 9.15972 5.16667 9.27083L3.5 11.5C3.38889 11.6389 3.375 11.7847 3.45833 11.9375C3.54167 12.0903 3.66667 12.1667 3.83333 12.1667ZM2.16667 15.5C1.70833 15.5 1.31583 15.3369 0.989167 15.0108C0.663055 14.6842 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663055 1.31583 0.989167 0.989167C1.31583 0.663055 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.6842 0.663055 15.0108 0.989167C15.3369 1.31583 15.5 1.70833 15.5 2.16667V13.8333C15.5 14.2917 15.3369 14.6842 15.0108 15.0108C14.6842 15.3369 14.2917 15.5 13.8333 15.5H2.16667Z"
          fill="#58AEDF"
        />
      </svg>
    ),
  },
  [POST_TYPE_IDS_ENUM.VIDEO]: {
    label: "Video",
    id: POST_TYPE_IDS_ENUM.VIDEO,
    svgIcon: (
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.20768 9.91658L11.666 7.70825C11.9299 7.54159 12.0618 7.30547 12.0618 6.99992C12.0618 6.69436 11.9299 6.45825 11.666 6.29158L8.20768 4.08325C7.9299 3.88881 7.64518 3.87492 7.35352 4.04158C7.06185 4.20825 6.91602 4.45131 6.91602 4.77075V9.22908C6.91602 9.54853 7.06185 9.79158 7.35352 9.95825C7.64518 10.1249 7.9299 10.111 8.20768 9.91658ZM2.33268 13.6666C1.87435 13.6666 1.48213 13.5035 1.15602 13.1774C0.829349 12.8508 0.666016 12.4583 0.666016 11.9999V1.99992C0.666016 1.54159 0.829349 1.14936 1.15602 0.823252C1.48213 0.496585 1.87435 0.333252 2.33268 0.333252H15.666C16.1243 0.333252 16.5168 0.496585 16.8435 0.823252C17.1696 1.14936 17.3327 1.54159 17.3327 1.99992V11.9999C17.3327 12.4583 17.1696 12.8508 16.8435 13.1774C16.5168 13.5035 16.1243 13.6666 15.666 13.6666H2.33268Z"
          fill="#63BD92"
        />
      </svg>
    ),
  },
  [POST_TYPE_IDS_ENUM.OFFERING]: {
    label: "Offering",
    id: POST_TYPE_IDS_ENUM.OFFERING,
    svgIcon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.99935 11.4584L11.3118 13.2084C11.4785 13.3334 11.6452 13.3404 11.8118 13.2292C11.9785 13.1181 12.0271 12.9654 11.9577 12.7709L11.0827 9.87508L13.3535 8.25008C13.5202 8.13897 13.5724 7.98619 13.5102 7.79175C13.4474 7.5973 13.3118 7.50008 13.1035 7.50008H10.3327L9.39518 4.45841C9.32574 4.26397 9.19379 4.16675 8.99935 4.16675C8.8049 4.16675 8.67296 4.26397 8.60352 4.45841L7.66602 7.50008H4.89518C4.68685 7.50008 4.55157 7.5973 4.48935 7.79175C4.42657 7.98619 4.47852 8.13897 4.64518 8.25008L6.91602 9.87508L6.04102 12.7709C5.97157 12.9654 6.02018 13.1181 6.18685 13.2292C6.35352 13.3404 6.52018 13.3334 6.68685 13.2084L8.99935 11.4584ZM8.99935 17.3334C7.84657 17.3334 6.76324 17.1145 5.74935 16.6767C4.73546 16.2395 3.85352 15.6459 3.10352 14.8959C2.35352 14.1459 1.7599 13.264 1.32268 12.2501C0.884905 11.2362 0.666016 10.1529 0.666016 9.00008C0.666016 7.8473 0.884905 6.76397 1.32268 5.75008C1.7599 4.73619 2.35352 3.85425 3.10352 3.10425C3.85352 2.35425 4.73546 1.76036 5.74935 1.32258C6.76324 0.885359 7.84657 0.666748 8.99935 0.666748C10.1521 0.666748 11.2355 0.885359 12.2493 1.32258C13.2632 1.76036 14.1452 2.35425 14.8952 3.10425C15.6452 3.85425 16.2388 4.73619 16.676 5.75008C17.1138 6.76397 17.3327 7.8473 17.3327 9.00008C17.3327 10.1529 17.1138 11.2362 16.676 12.2501C16.2388 13.264 15.6452 14.1459 14.8952 14.8959C14.1452 15.6459 13.2632 16.2395 12.2493 16.6767C11.2355 17.1145 10.1521 17.3334 8.99935 17.3334Z"
          fill="#8A7EDF"
        />
      </svg>
    ),
  },
  [POST_TYPE_IDS_ENUM.DOCUMENT]: {
    label: "Doc",
    id: POST_TYPE_IDS_ENUM.DOCUMENT,
    svgIcon: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66667 12.1667H8.83333C9.06944 12.1667 9.2675 12.0867 9.4275 11.9267C9.58694 11.7672 9.66667 11.5694 9.66667 11.3333C9.66667 11.0972 9.58694 10.8992 9.4275 10.7392C9.2675 10.5797 9.06944 10.5 8.83333 10.5H4.66667C4.43056 10.5 4.2325 10.5797 4.0725 10.7392C3.91306 10.8992 3.83333 11.0972 3.83333 11.3333C3.83333 11.5694 3.91306 11.7672 4.0725 11.9267C4.2325 12.0867 4.43056 12.1667 4.66667 12.1667ZM4.66667 8.83333H11.3333C11.5694 8.83333 11.7672 8.75333 11.9267 8.59333C12.0867 8.43389 12.1667 8.23611 12.1667 8C12.1667 7.76389 12.0867 7.56583 11.9267 7.40583C11.7672 7.24639 11.5694 7.16667 11.3333 7.16667H4.66667C4.43056 7.16667 4.2325 7.24639 4.0725 7.40583C3.91306 7.56583 3.83333 7.76389 3.83333 8C3.83333 8.23611 3.91306 8.43389 4.0725 8.59333C4.2325 8.75333 4.43056 8.83333 4.66667 8.83333ZM4.66667 5.5H11.3333C11.5694 5.5 11.7672 5.42 11.9267 5.26C12.0867 5.10056 12.1667 4.90278 12.1667 4.66667C12.1667 4.43056 12.0867 4.2325 11.9267 4.0725C11.7672 3.91306 11.5694 3.83333 11.3333 3.83333H4.66667C4.43056 3.83333 4.2325 3.91306 4.0725 4.0725C3.91306 4.2325 3.83333 4.43056 3.83333 4.66667C3.83333 4.90278 3.91306 5.10056 4.0725 5.26C4.2325 5.42 4.43056 5.5 4.66667 5.5ZM2.16667 15.5C1.70833 15.5 1.31583 15.3369 0.989167 15.0108C0.663055 14.6842 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663055 1.31583 0.989167 0.989167C1.31583 0.663055 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.6842 0.663055 15.0108 0.989167C15.3369 1.31583 15.5 1.70833 15.5 2.16667V13.8333C15.5 14.2917 15.3369 14.6842 15.0108 15.0108C14.6842 15.3369 14.2917 15.5 13.8333 15.5H2.16667Z"
          fill="#EBAC33"
        />
      </svg>
    ),
  },
};
