import { getStylesObjFromCss } from "../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      display: "flex",
      border: "1px solid #cdcdcd",
      borderRadius: 4,
      cursor: "pointer",
      overflow: "hidden",
      margin: "8px 16px",
      transition: "all 0.1s",
      textDecoration: "none !important",
      maxHeight: "118px",
      color: "black",

      "&:visited": {
        color: "black",
      },

      "&:active": {
        color: "black",
      },

      "&:hover": {
        backgroundColor: "#cdcdcd20",
        color: "black",
      },
    },

    thumbnailContainer: {
      aspectRatio: "1 / 1",
      lineHeight: 0,
      width: "118px",
      height: "118px",
      display: "grid",
      placeItems: "center",
      background: "#cdcdcd59",
      "& svg": {
        fontSize: "52px",
        opacity: 0.5,
      },
    },
    thumbnail: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    dataContainer: {
      flex: 1,
      padding: "12px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },

    title: {
      fontSize: "14px",
      lineHeight: "normal",
      fontWeight: 700,
      display: "flex",

      "& span": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "10px",
        flex: 1,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
      },
    },

    description: {
      fontSize: "12px",
      lineHeight: "normal",
      display: "flex",
      marginTop: "4px",

      "& span": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "10px",
        flex: 1,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
      },
    },

    iconContainer: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      marginTop: "4px",

      "& img": {
        height: "18px",
      },

      "& div": {
        fontSize: "14px",
      },
    },
  },
  componentName: "LinkPreview",
});
