import React from "react";
import { styles } from "./DeleteConfirmationModal.styles";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import Modal from "../Modal/Modal";
import ModalContent from "../Modal/ModalContent/ModalContent";
import ModalHeader from "../Modal/ModalHeader/ModalHeader";
import ModalFooter from "../Modal/ModalFooter/ModalFooter";
import Button from "../Button/Button";
import { IDeleteConfirmationModalProps } from "./DeleteConfirmationModal.types";
import ModalBody from "../../../components/common/Modal/ModalBody/ModalBody";

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onDelete,
}: IDeleteConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader onClose={onClose}>
          <div className={styles.titleContainer}>
            <ReportProblemRoundedIcon
              htmlColor="#BF2600"
              className={styles.titleDangerIcon}
            />
            Confirm deletion
          </div>
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <ReportProblemRoundedIcon
            htmlColor="#BF2600"
            className={styles.bodyDangerIcon}
          />
          <div>Are you sure you want to proceed?</div>
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button onClick={onClose} color="unset" size="unset">
            Cancel
          </Button>
          <Button
            onClick={onDelete}
            color="unset"
            className={styles.primaryButton}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmationModal;
