import React, { Suspense } from "react";
import { IPostImageProps } from "./PostImage.types";
import { evaluateHidePostImage } from "../../../utils/BrandedCommunity/PostImage/evaluateHidePostImage";

const PostImage = React.lazy(() => import("./PostImage"));

const LazyLoadedPostImage = (props: IPostImageProps) => {
  const { showImg, imgSrc } = props;
  if (evaluateHidePostImage({ imgSrc, showImg })) return null;
  return (
    <Suspense fallback={null}>
      <PostImage {...props} />
    </Suspense>
  );
};

export default LazyLoadedPostImage;
