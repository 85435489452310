import { getStylesObjFromCss } from "../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    btn: {},

    "btn-color-unset": {},
    "btn-color-primary": {
      color: "#ffffff !important",
      backgroundColor: "#493AB1 !important", // TODO @mayank
    },
    "btn-color-secondary": {
      backgroundColor: "#ffffff !important",
      color: "#493AB1 !important", // TODO @mayank
      outline: "2px solid #493AB1 !important", // TODO @mayank
      outlineOffset: "-2px",
    },

    "btn-size-unset": {},
    "btn-size-medium": {
      padding: "12px !important",
      minWidth: "107px !important",
    },
    "btn-size-thin": {
      fontSize: "14px !important",
      padding: "8px 12px !important",
      minWidth: "95px !important",
    },

    "btn-borderRadius-unset": {},
    "btn-borderRadius-rounded": {
      borderRadius: "5px !important",
    },
    "btn-borderRadius-pill": {
      borderRadius: "99999999999px !important",
    },
  },
  componentName: "Button",
});
