import { getStylesObjFromCss } from "../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      background: "white",
      position: "relative",
      zIndex: 1,
    },

    accordionRoot: {
      backgroundColor: "transparent !important",
    },

    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      margin: "12px 0px",
    },

    label: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      cursor: "pointer",
      color: "#272522",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "normal",
      whiteSpace: "pre-wrap",
    },

    expandCta: {
      padding: "0px !important",
      transition: "transform .2s ease-in-out !important",
      "& svg": {
        color: "#272522",
      },
    },
    expanded: {
      transform: "rotate(90deg)",
    },

    details: {
      padding: "0px !important",
    },

    divider: {
      background: "#E9E9E9",
      height: "2px",
      border: "none",
      margin: 0,
    },

    addCta: {
      marginTop: "10px !important",
      marginBottom: "12px !important",
    },

    draggable: {
      background: "#F7F5FF !important",
    },

    actions: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      "& button": {
        padding: 0,
        "& svg": {
          fontSize: "21.33px",
        },
      },
      "& svg": {
        color: "#948AD9",
      },
    },
    dragCta: {
      cursor: "grab",
    },
  },
  componentName: "Collection",
});
