import { getStylesObjFromCss } from "../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      borderLeft: "1px solid #e7e6e4",
      maxWidth: "342px",
      background: "white",
    },
  },
  componentName: "MembersPanel",
});
