import {
  CERTIFICATE_TRIGGERS_TYPE,
  CONTENT_TYPE,
  COURSE_UNLOCKING_TYPES,
} from "./RecordedContent.constants";
import { ICreator } from "../../../../interfaces/creator";
import { IRecordedContent } from "./RecordedContent.interfaces";
import { checkIsObjectEmpty } from "../../../../utils/common/dataTypes/object";
import { checkIsStringEmpty } from "../../../../utils/common/dataTypes/string";
import { isFuture, parseISO } from "date-fns";
import { checkIsArrayEmpty } from "../../../../utils/common/dataTypes/array";

/**
 * this function is used to check if a particular section is locked
 * used in exly-creator-app/RecordedContent
 * @param section - section data
 * @param previousSection - previous section data
 * @param courseData - course data
 * @returns {boolean} True if section is locked, otherwise false.
 */
export const isSectionLocked = ({
  section,
  previousSection,
  courseData,
}: {
  section: IRecordedContent.IContentSectionInterface;
  previousSection?: IRecordedContent.IContentSectionInterface;
  courseData: IRecordedContent.ICourseDataInterface;
}): boolean => {
  const { unlocking_type: unlockingType } = courseData;

  const isPostPreviousCompletion =
    unlockingType === COURSE_UNLOCKING_TYPES.POST_PREVIOUS_COMPLETION;

  if (isPostPreviousCompletion)
    return previousSection ? !previousSection.is_completed : false;

  if (section?.unlock_time) {
    const unlockTime = parseISO(section.unlock_time);
    return isFuture(unlockTime);
  }

  return false;
};

/**
 * this function is used to check if a particular lesson is locked
 * used in exly-creator-app/RecordedContent
 * @param subCategoryItem - lesson data
 * @param previousCategoryItem - previous section data
 * @param previousSubcategory - previous lesson data
 * @param courseData - course data
 * @returns {boolean} True if lesson is locked, otherwise false.
 */
export const isLessonLocked = ({
  subCategoryItem: lesson,
  previousCategoryItem: previousSection,
  previousSubcategory: previousLesson,
  courseData,
}: {
  subCategoryItem: IRecordedContent.IContentLessonInterface;
  previousCategoryItem?: IRecordedContent.IContentSectionInterface;
  previousSubcategory?: IRecordedContent.IContentLessonInterface;
  courseData: IRecordedContent.ICourseDataInterface;
}): boolean => {
  const {
    unlocking_type: unlockingType,
    has_lesson_unlocking: hasLessonUnlocking,
  } = courseData;

  const isPostPreviousCompletion =
    unlockingType === COURSE_UNLOCKING_TYPES.POST_PREVIOUS_COMPLETION;

  if (isPostPreviousCompletion) {
    if (hasLessonUnlocking && previousLesson)
      return previousLesson ? !previousLesson.is_completed : false;
    return previousSection ? !previousSection.is_completed : false;
  }

  if (lesson?.unlock_time) {
    const unlockTime = parseISO(lesson.unlock_time);
    return isFuture(unlockTime);
  }

  return false;
};

// used in:
//   - exly-creator-app; RecordedContent;
/**
 * @returns text in lowercase to replace "section" word in recorded content used across host site
 */
export const getWordUsedForCourseSection = (
  creatorData: ICreator.ICreatorData,
): string =>
  (creatorData?.custom_word_for_course_section || "section").toLowerCase();

// used in:
//   - exly-creator-app; RecordedContent;
/**
 * @returns text in lowercase to replace "lesson" word in recorded content used across host site
 */
export const getWordUsedForCourseLesson = (
  creatorData: ICreator.ICreatorData,
): string =>
  (creatorData?.custom_word_for_course_lesson || "lesson").toLowerCase();

// used in:
//   - exly-creator-app; RecordedContent;
/**
 * @returns text in lowercase to replace "mark" word for score in recorded content used across host site
 */
export const getWordUsedForCourseMark = (
  creatorData?: ICreator.ICreatorData,
): string => (creatorData?.custom_word_for_course_mark || "mark").toLowerCase();

// used in:
//   - exly-creator-app; RecordedContent;
/**
 * @returns text in lowercase to replace "exam" word in recorded content used across host site
 */
export function getWordUsedForCourseExam(creatorData: ICreator.ICreatorData) {
  return (creatorData?.custom_word_for_course_exam || "exam").toLowerCase();
}

// used in:
//   - exly-creator-app; RecordedContent;
/**
 * @returns text in lowercase to replace "course" word for recorded content used across host site
 */
export const getWordUsedForCourse = (
  creatorData?: ICreator.ICreatorData,
  customDefault?: string,
): string =>
  (
    creatorData?.custom_word_for_course ||
    customDefault ||
    "course"
  ).toLowerCase();

// used in:
//   - exly-creator-app; RecordedContent;
/**
 * @returns text in lowercase to replace "question" word in recorded content used across host site
 */
export function getWordUsedForCourseQuestion(
  creatorData?: ICreator.ICreatorData,
): string {
  return (
    creatorData?.custom_word_for_course_question || "question"
  ).toLowerCase();
}

// used in:
//   - exly-creator-app; RecordedContent/CertificateDetails;
export const isExamLeft = ({
  content: {
    type,
    last_questionnaire_attempt: lastQuestionnaireAttempt,
    questionnaire_details: questionnaireDetails,
  },
}: {
  content: IRecordedContent.IContentInterface;
}) => {
  const isExam = type === CONTENT_TYPE.EXAM;
  if (isExam) {
    const hasExamDetails = !checkIsObjectEmpty(questionnaireDetails);
    const { attempts_taken: attemptsTaken = 0, is_passed: isPassed } =
      lastQuestionnaireAttempt || {};
    const { no_of_attempts_allowed: totalAttempts = 0 } =
      questionnaireDetails || {};
    return hasExamDetails ? attemptsTaken < totalAttempts && !isPassed : true;
  }
  return false;
};

// used in:
//   - exly-creator-app; RecordedContent/ContentList;
export const getExamsLeftToBeCompleted = ({
  subCategory,
  certificateDetails,
}: {
  subCategory: IRecordedContent.IContentLessonInterface;
  certificateDetails: IRecordedContent.ICertificateDetailsInterface;
}) => {
  // If certificateDetails is empty, return an empty array
  if (checkIsObjectEmpty(certificateDetails)) return [];

  const {
    certificate_preview_url: certificatePreviewUrl,
    trigger_time_type: certificateTriggerType,
    course_specific_details: courseSpecificDetails,
  } = certificateDetails;

  // Check if the certificate preview URL is available
  const hasCertificate = !checkIsStringEmpty(certificatePreviewUrl);

  // Check the certificate trigger type
  const isAllExamMandatory =
    certificateTriggerType ===
    CERTIFICATE_TRIGGERS_TYPE.POST_ALL_EXAM_COMPLETION;
  const isSpecificExamMandatory =
    certificateTriggerType ===
    CERTIFICATE_TRIGGERS_TYPE.POST_SPECIFIC_EXAM_COMPLETION;

  // If no certificate or neither mandatory exam type, return an empty array
  if (!hasCertificate || (!isSpecificExamMandatory && !isAllExamMandatory))
    return [];

  const { contents } = subCategory;

  if (isAllExamMandatory) {
    // Filter and return all exams that are left to be completed
    const examsLeftToBeCompleted = (contents ?? []).filter((content) =>
      isExamLeft({ content }),
    );
    return examsLeftToBeCompleted;
  }
  if (isSpecificExamMandatory && courseSpecificDetails) {
    const { trigger_exam: triggerExam } = courseSpecificDetails;

    if (triggerExam) {
      // Filter and return the specific exam that is left to be completed
      const examsLeftToBeCompleted = (contents ?? []).filter(
        (content) =>
          content?.uuid ===
            certificateDetails?.course_specific_details?.trigger_exam?.uuid &&
          isExamLeft({ content }),
      );
      return examsLeftToBeCompleted;
    }
  }
  return [];
};

/**
 * used for lesson based tracking
 */
export const getCourseCompletionProgress = (
  courseSections: IRecordedContent.IContentSectionInterface[],
) => {
  if (checkIsArrayEmpty(courseSections))
    return {
      completionPercent: 0,
      totalLessons: 0,
      completedLessons: 0,
    };

  let totalLessons = 0;
  let completedLessons = 0;

  courseSections.forEach(
    ({
      total_lessons: sectionTotalLessons,
      completed_lessons: sectionCompletedLessons,
    }) => {
      totalLessons += sectionTotalLessons;
      completedLessons += sectionCompletedLessons;
    },
  );

  return {
    completionPercent: Math.floor((completedLessons / totalLessons) * 100),
    totalLessons,
    completedLessons,
  };
};
