import { Collapse } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useToggleState } from "../../../utils/common/dataTypes/boolean";
import { IReadMoreWrapperProps } from "./ReadMoreWrapper.types";

const ReadMoreWrapper = ({
  children = null,
  collapsedHeight = 50,
  ExpandToggleButton,
  childDivProps = {},
}: IReadMoreWrapperProps) => {
  const [
    expandCollapseFunctionalityIsEnabled,
    enableExpandCollapseFunctionality,
    disableExpandCollapseFunctionality,
  ] = useToggleState(true);
  const [isExpanded, , collapse, toggleIsExpanded] = useToggleState(false);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (expandCollapseFunctionalityIsEnabled && isExpanded) return; // dont need to collapse on content change if already expanded

    if (ref.current) {
      if (ref.current.clientHeight > collapsedHeight) {
        enableExpandCollapseFunctionality();
        collapse();
      } else {
        disableExpandCollapseFunctionality();
      }
    }
  }, [children, childDivProps]); // the content might change

  const baseElement = (
    <div ref={ref} {...childDivProps}>
      {children}
    </div>
  );

  return expandCollapseFunctionalityIsEnabled ? (
    <>
      <Collapse collapsedSize={collapsedHeight} in={isExpanded}>
        {baseElement}
      </Collapse>
      <ExpandToggleButton onClick={toggleIsExpanded} isExpanded={isExpanded} />
    </>
  ) : (
    baseElement
  );
};

export default ReadMoreWrapper;
