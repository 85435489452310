import React, { useState } from "react";
import PopoverBase from "@mui/material/Popover";
import { classNames } from "../../../utils/common/styling";
import Drawer from "@mui/material/Drawer";
import { useIsDesktop } from "../../../utils/common/screen";
import { styles } from "./Popover.styles";
import { IAnchorComponentProps, IPopoverProps } from "./Popover.types";

const Popover = ({
  PopoverComponent,
  AnchorComponent,
  wrapperClassName,
  onClose = () => {},
  usePopoverInMobile,
  popoverProps,
}: IPopoverProps) => {
  const isDesktop = useIsDesktop();
  const [anchor, setAnchor] = useState<Element | null>(null);
  const isOpen = Boolean(anchor);

  const openPopover: IAnchorComponentProps["openPopover"] = (event) => {
    setAnchor(event.currentTarget);
  };

  const closePopover: IPopoverProps["onClose"] = (e) => {
    setAnchor(null);
    onClose(e);
  };

  const commonProps = { open: isOpen, onClose: closePopover };

  const isPopoverComponent = isDesktop || usePopoverInMobile;

  const _PopoverComponent = <PopoverComponent closePopover={closePopover} />;

  const PopoverWrapperComponent = isPopoverComponent ? (
    <PopoverBase
      classes={{ paper: styles.popoverPaper }}
      anchorEl={anchor}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      {...commonProps}
      {...popoverProps}
    >
      {_PopoverComponent}
    </PopoverBase>
  ) : (
    <Drawer
      classes={{ paper: styles.drawerPaper }}
      anchor="bottom"
      {...commonProps}
    >
      {_PopoverComponent}
    </Drawer>
  );

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <AnchorComponent openPopover={openPopover} />
      {PopoverWrapperComponent}
    </div>
  );
};

export default Popover;
