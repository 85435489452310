import React, { Suspense } from "react";
import { IPostDocProps } from "./PostDoc.types";
import { evaluateHidePostDoc } from "../../../utils/BrandedCommunity/PostDoc/evaluateHidePostDoc";

const PostDoc = React.lazy(() => import("./PostDoc"));

const LazyLoadedPostDoc = (props: IPostDocProps) => {
  const { showDoc, docUrl } = props;
  if (evaluateHidePostDoc({ showDoc, docUrl })) return null;
  return (
    <Suspense fallback={null}>
      <PostDoc {...props} />
    </Suspense>
  );
};

export default LazyLoadedPostDoc;
