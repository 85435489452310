import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      maxWidth: "342px",
      overflowY: "auto",
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F5F5F5",
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      },
      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#555",
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
      },
    },

    cover: {
      width: "100%",
      position: "relative",
    },

    body: {
      borderRadius: "0px 40px 0px 0px",
      backgroundColor: "white",
      marginTop: "-48px",
      position: "relative",
      paddingBottom: "16px",
    },

    config: {
      marginTop: "18.67px",
    },

    divider: {
      margin: 0,
      background: "#E9E9E9",
      height: "2px",
      border: "none",
    },
  },
  componentName: "SidebarWrapper",
});
