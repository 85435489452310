import React, { FC } from "react";

import { Avatar, IconButton } from "@mui/material";

import EnvelopeIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import MessageIcon from "@mui/icons-material/QuestionAnswerOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DefaultImageIcon from "../../../../../../../assets/images/default.png";

import { classNames } from "../../../../../../../utils/common/styling";

import { IMember } from "./IMember";
import { styles } from "./Member.styles";

const Member: FC<IMember.IProps> = ({
  avatarProps,
  className,
  member,
  index,
  showActions,
  onClick,
  renderCustomActions,
  onClickMail,
  onClickMessage,
  onClickWhatsapp,
}) => {
  const { title, image } = member;

  return (
    <div
      className={classNames(styles.root, className)}
      onClick={() => onClick?.({ member, index })}
    >
      <div className={styles.profileContainer}>
        <Avatar src={image ?? DefaultImageIcon} alt={title} {...avatarProps} />
        {title}
      </div>

      {showActions && (
        <div className={styles.ctaContainer}>
          {renderCustomActions
            ? renderCustomActions({ member, index })
            : [
                {
                  id: 1,
                  icon: MessageIcon,
                  onClick: () => onClickMessage?.({ member, index }),
                },
                {
                  id: 2,
                  icon: EnvelopeIcon,
                  onClick: () => onClickMail?.({ member, index }),
                },
                {
                  id: 3,
                  icon: WhatsAppIcon,
                  onClick: () => onClickWhatsapp?.({ member, index }),
                },
              ].map(({ id, icon: Icon, onClick }) => (
                <IconButton
                  key={id}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (onClick) onClick();
                  }}
                >
                  <Icon />
                </IconButton>
              ))}
        </div>
      )}
    </div>
  );
};

export default Member;
