import React, { Suspense } from "react";
import { IPostOfferingProps } from "./PostOffering.types";
import { evaluateHidePostOffering } from "../../../utils/BrandedCommunity/PostOffering/evaluateHidePostOffering";

const PostOffering = React.lazy(() => import("./PostOffering"));

const LazyLoadedPostOffering = (props: IPostOfferingProps) => {
  const { showOffering, offering } = props;
  if (evaluateHidePostOffering({ showOffering, offering })) return null;
  return (
    <Suspense fallback={null}>
      <PostOffering {...props} />
    </Suspense>
  );
};

export default LazyLoadedPostOffering;
