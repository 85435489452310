import { ICreatorTheme } from "../../../../features/Common/modules/Theme/theme.interface";
import { getColorsFromCreatorTheme } from "../../../../features/Common/modules/Theme/utils/Theme.utils";
import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    wrapperRoot: {
      marginRight: "16px",
      marginLeft: "18px",
    },

    baseRoot: {
      fontSize: "16px !important",
      lineHeight: "normal !important",
      textTransform: "none !important" as any,
      padding: "0px !important",
    },

    secondaryRoot: {
      color: "rgba(39, 37, 34, 0.60) !important",
    },

    baseStartIcon: {
      borderRadius: "50%",
      marginLeft: "0px !important",
      fontSize: "18px !important",
    },

    secondaryStartIcon: {
      background: "rgba(39, 37, 34, 0.3) !important",
      color: "white",
    },
  },
  componentName: "SidebarCta",
});

export const getThemedStyles = ({ theme }: { theme: ICreatorTheme }) => {
  const colors = getColorsFromCreatorTheme(theme);
  return getStylesObjFromCss({
    styles: {
      root: {
        color: (colors.color1 || "#493AB1") + " !important",
      },
      startIcon: colors.color1
        ? {
            color: (colors.color3 || "white") + " !important",
            background: colors.color1 + " !important",
          }
        : {
            backgroundImage:
              "linear-gradient(180deg, #493AB1 0%, #276ABE 100%) !important",
            color: "white !important",
          },
    },
    componentName: "SidebarCta",
  });
};
