import React, { Suspense } from "react";
import { ICommentEditor } from "./CommentEditor.types";

const CommentEditor = React.lazy(() => import("./CommentEditor"));

export interface ILazyLoadedCommentEditorProps
  extends ICommentEditor.ICommentEditorProps {
  show?: boolean;
}

const LazyLoadedCommentEditor = ({
  show,
  ...props
}: ILazyLoadedCommentEditorProps) => {
  if (!show) return null;
  return (
    <Suspense fallback={null}>
      <CommentEditor {...props} />
    </Suspense>
  );
};

export default LazyLoadedCommentEditor;
