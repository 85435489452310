import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      padding: "20px 24px",
      overflow: "auto",
    },
  },
  componentName: "ModalBody",
});
