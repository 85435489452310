import { MOBILE_SCREEN_WIDTH } from "../../../../../constants/common/screen";
import { ICreatorTheme } from "../../../../../features/Common/modules/Theme/theme.interface";
import { getColorsFromCreatorTheme } from "../../../../../features/Common/modules/Theme/utils/Theme.utils";
import { getStylesObjFromCss } from "../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    moreButton: {
      cursor: "pointer",
    },
    moreMenuContainer: {
      paddingBottom: "8px",
      paddingTop: "8px",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    moreMenuItem: {
      cursor: "pointer",
      paddingRight: "12px",
      paddingLeft: "12px",
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      [`@media(max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        paddingTop: "6px",
        paddingBottom: "6px",
      },
    },
    editIcon: {
      marginRight: "4px",
    },
    hideUnhideIcon: {
      marginRight: "4px",
    },
    deleteIcon: {
      marginRight: "8px",
    },
  },
  componentName: "MoreButtonAndMenu",
});

export const getThemedStyles = ({ theme }: { theme: ICreatorTheme }) => {
  const { color1 } = getColorsFromCreatorTheme(theme);
  return getStylesObjFromCss({
    styles: {
      editIcon: {
        color: color1 || "#493ab1",
      },
    },
    componentName: "MoreButtonAndMenu",
  });
};
