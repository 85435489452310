import React, { FC } from "react";
import { Draggable } from "react-beautiful-dnd";

import { classNames } from "../../../../../utils/common/styling";

import { AccordionDetails, IconButton, styled } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import RightIcon from "@mui/icons-material/ChevronRightRounded";
import EditIcon from "@mui/icons-material/CreateOutlined";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";

import SectionCTA from "../../SectionCTA/SectionCTA";
import SidebarCta from "../../SidebarCta/SidebarCta";

import { styles } from "./Collection.styles";
import { ICollection } from "./ICollection";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  padding: "0px 16px 0px 18px",
  minHeight: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
    cursor: "default",
  },
}));

const Collection: FC<ICollection.IProps> = ({
  expanded,
  showActions,
  showDivider: showDiviver,
  index,
  collection,
  selectedChannelId,
  onClick,
  onClickChannel,
  onEdit,
  onAddChannel,
}) => {
  const { label, channels, id } = collection;

  return (
    <Draggable draggableId={`draggable-${id}`} index={index}>
      {(provided, snapshot) => (
        <div
          className={classNames(styles.root)}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Accordion
            classes={{
              root: classNames(
                styles.accordionRoot,
                snapshot.isDragging && styles.draggable,
              ),
            }}
            expanded={expanded}
          >
            <AccordionSummary>
              <div
                className={classNames(
                  styles.header,
                  !showDiviver && styles.noMargin,
                )}
              >
                <div
                  className={styles.label}
                  onClick={(event) =>
                    onClick?.({ collection: collection, event, index })
                  }
                >
                  {label}
                  <IconButton
                    className={classNames(
                      styles.expandCta,
                      expanded && styles.expanded,
                    )}
                  >
                    <RightIcon />
                  </IconButton>
                </div>
                {showActions && (
                  <div className={styles.actions}>
                    <IconButton
                      onClick={(event) => onEdit?.({ collection, event })}
                    >
                      <EditIcon />
                    </IconButton>
                    <div {...provided.dragHandleProps}>
                      <DragIcon className={styles.dragCta} />
                    </div>
                  </div>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className={styles.details}>
              {channels?.map((channel, index) => {
                const { label, icon, id } = channel;
                return (
                  <SectionCTA
                    key={id}
                    label={label}
                    icon={icon}
                    onClick={(event) =>
                      onClickChannel?.({ channel, index, event })
                    }
                    active={selectedChannelId === id}
                  />
                );
              })}
              {showActions && (
                <SidebarCta
                  label="Add channel"
                  ctaVariant="secondary"
                  className={styles.addCta}
                  onClick={(event) => onAddChannel?.({ collection, event })}
                />
              )}
            </AccordionDetails>
          </Accordion>
          {showDiviver && !snapshot.isDragging && (
            <hr className={styles.divider} />
          )}
        </div>
      )}
    </Draggable>
  );
};

export default Collection;
