import { IForm } from "../Form.interfaces";

const useInsertTextAtCursor = ({
  inputRef,
}: IForm.IUseInsertTextAtCursorProps): IForm.IUseInsertTextAtCursorReturn => {
  const insertTextAtCursor = (text: string) => {
    const input = inputRef.current;

    if (input) {
      const startPos = input.selectionStart || 0;
      const endPos = input.selectionEnd || 0;
      const newPos = startPos + text.length; // Calculate the new cursor position
      const newValue =
        input.value.substring(0, startPos) +
        text +
        input.value.substring(endPos, input.value.length);

      // Update the input's value
      input.value = newValue;

      // Using setTimeout to ensure the cursor position is set after state update
      setTimeout(() => {
        input.setSelectionRange(newPos, newPos);
      }, 0);

      // Trigger the change event for controlled components
      input.dispatchEvent(new Event("input", { bubbles: true }));
    }
  };

  return {
    insertTextAtCursor,
  };
};

export default useInsertTextAtCursor;
