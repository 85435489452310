export const EXIT_MODAL_CLOSE_DURATION = 30; // in seconds

export const QUESTIONNAIRE_PAGES = {
  START_SCREEN: 1,
  ASSESSMENT: 2,
  RESULT: 3,
};

export const QUESTIONNAIRE_ANSWER_KEYS = {
  question_uid: "question_uid",
  question_type: "question_type",
  answer: "answer",
  selected_options_uid_list: "selected_options_uid_list",
};

export const TOTAL_PAGES = Object.keys(QUESTIONNAIRE_PAGES).length;

export const SECONDS_IN_ONE_HOUR = 60 * 60;
export const SECONDS_IN_ONE_MINUTE = 60;
export const MINUTES_IN_ONE_HOUR = 60;

export const DEFAULT_TIME_IN_MINUTES = 0;
