import React, { FC } from "react";

import Member from "./components/Member/Member";

import { classNames } from "../../../../../utils/common/styling";

import { IMembersList } from "./IMembersList";
import { styles } from "./MembersList.styles";

const MembersList: FC<IMembersList.IProps> = ({
  className,
  customListComponent,
  members,
  showActions,
  memeberItemClassName,
  onClickMember,
  ...restProps
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      {members?.map((member, index) =>
        customListComponent ? (
          customListComponent({ member, index, showActions })
        ) : (
          <Member
            key={member?.id}
            className={memeberItemClassName}
            member={member}
            showActions={showActions}
            index={index}
            onClick={onClickMember}
            {...restProps}
          />
        ),
      )}
    </div>
  );
};

export default MembersList;
