import {
  file_mime_types,
  images_format_keys,
} from "../../File/constants/File.constants";

export const IMAGE_CDN_FORMATS = {
  AUTO: "auto",
  AVIF: images_format_keys.avif,
  JPEG: images_format_keys.jpeg,
  JPG: images_format_keys.jpg,
  PNG: images_format_keys.png,
  WEBP: images_format_keys.webp,
  SVG: images_format_keys.svg,
  TIFF: images_format_keys.tiff,
} as const;

export const ACCEPTED_IMAGES_FORMAT_IN_OPTIMIZATING_CDN = [
  ...file_mime_types[images_format_keys.png],
  ...file_mime_types[images_format_keys.jpeg],
  ...file_mime_types[images_format_keys.jpg],
  ...file_mime_types[images_format_keys.avif],
  ...file_mime_types[images_format_keys.svg],
  ...file_mime_types[images_format_keys.tiff],
  ...file_mime_types[images_format_keys.webp],
];
