import React from "react";
import { styles } from "./PostTypeSelector.styles";
import { IPostTypeSelectorProps } from "./PostTypeSelector.types";
import { POST_TYPE_DETAILS_MAP } from "../../../constants/BrandedCommunity/Post";
import { classNames } from "../../../utils/common/styling";

const PostTypeSelector = ({
  postTypeIdsToShowList,
  onPostTypeClick,
  disablePostTypeClick,
}: IPostTypeSelectorProps) => {
  return (
    <>
      {postTypeIdsToShowList.map((typeId) => {
        if (!POST_TYPE_DETAILS_MAP[typeId]) return null;
        return (
          <span
            key={typeId}
            className={classNames(
              styles.postTypeOption,
              disablePostTypeClick && styles["postTypeOption-disabled"],
            )}
            onClick={() => {
              if (disablePostTypeClick) return;
              onPostTypeClick(typeId);
            }}
          >
            {POST_TYPE_DETAILS_MAP[typeId].svgIcon}
            <span className={styles.postTypeOptionLabel}>
              {POST_TYPE_DETAILS_MAP[typeId].label}
            </span>
          </span>
        );
      })}
    </>
  );
};

export default PostTypeSelector;
