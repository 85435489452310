import React, { Suspense } from "react";
import { IChatWindowProps } from "./ChatWindow.types";

const ChatWindow = React.lazy(() => import("./ChatWindow"));

const LazyLoadedChatWindow = (props: IChatWindowProps) => {
  const { appId, userId, channelUrl } = props || {};
  if (!channelUrl || !appId || !userId) return null;

  return (
    <Suspense fallback={null}>
      <ChatWindow {...props} />
    </Suspense>
  );
};

export default LazyLoadedChatWindow;
