import { checkIsStringEmpty } from "../../../../../../../utils/common/dataTypes/string";
import {
  SITES_REGEX_DATA_MAPPING,
  SNAPDEAL_BROKEN_PREVIEW_IMAGE_REGEX,
  SNAPDEAL_PREVIEW_BROKEN_IMAGE_LINK_PREFIX,
} from "../constants/LinkPreview.data";

/**
 * Retrieves link preview data for unsupported sites based on the provided URL.
 * @param url The URL for which link preview data is to be fetched.
 * @returns Link preview data object for the unsupported site, or null if URL is empty or unsupported.
 */
export const getUnsupportedSiteLinkPreviewData = (url: string) => {
  if (checkIsStringEmpty(url)) return null;

  const hostName = new URL(url).hostname;

  return (
    Object.values(SITES_REGEX_DATA_MAPPING).find(({ regexes }) =>
      regexes.some((regex) => regex.test(hostName)),
    )?.data ?? null
  );
};

/**
 * Checks if the given URL is a Snapdeal image URL with a broken preview link.
 * @param url The URL to check.
 * @returns True if the URL matches the Snapdeal broken preview image regex, false otherwise.
 */
const isSnapdealImage = (url: string) =>
  !checkIsStringEmpty(url) && SNAPDEAL_BROKEN_PREVIEW_IMAGE_REGEX.test(url);

/**
 * Fixes a Snapdeal image URL with a broken preview link by removing the incorrect prefix.
 * @param url The Snapdeal image URL with a broken preview link.
 * @returns The corrected URL with the unnecessary prefix removed, or the original URL if not applicable.
 */
const getFixedSnapdealUrl = (url: string) =>
  url.startsWith(SNAPDEAL_PREVIEW_BROKEN_IMAGE_LINK_PREFIX)
    ? url.replace(SNAPDEAL_PREVIEW_BROKEN_IMAGE_LINK_PREFIX, "https://")
    : url;

/**
 * Retrieves the correct preview image URL by fixing Snapdeal image URLs with broken preview links.
 * @param url The URL of the image to retrieve.
 * @returns The corrected image URL with the unnecessary prefix removed, or the original URL if not a Snapdeal broken preview image.
 */
export const getPreviewImage = (url: string) =>
  isSnapdealImage(url) ? getFixedSnapdealUrl(url) : url;
