import { ICreatorTheme } from "../../../../features/Common/modules/Theme/theme.interface";
import { getColorsFromCreatorTheme } from "../../../../features/Common/modules/Theme/utils/Theme.utils";
import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      cursor: "pointer",
      padding: "8px 8px 8px 18px",
      boxSizing: "border-box",
      userSelect: "none",
      borderLeft: "2px solid transparent",
      transition: "border 0.1s",
      "&:hover": {
        background: "#f0f0f0",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "8px",
    },

    actions: {
      display: "flex",
      alignItems: "center",
      "& button": {
        padding: 0,
        "& svg": {
          fontSize: "21.33px",
        },
      },
      "& svg": {
        color: "#948AD9",
      },
    },

    active: {
      backgroundColor: "#f0f0f0",
    },

    labelContainer: {
      fontSize: "16px",
      lineHeight: "normal",
      display: "flex",
      alignItems: "center",
      gap: "14px",
      whiteSpace: "pre-wrap",
    },

    notActiveText: {
      color: "#272522",
    },
    labelText: {
      wordBreak: "break-word",
    },
  },
  componentName: "SectionCta",
});

export const getThemedStyles = ({ theme }: { theme: ICreatorTheme }) => {
  const colors = getColorsFromCreatorTheme(theme);
  return getStylesObjFromCss({
    styles: {
      active: {
        borderLeft: "2px solid " + (colors.color1 || "#493AB1"),
      },

      activeText: colors.color1
        ? { color: colors.color1 }
        : {
            background: "-webkit-linear-gradient(#493AB1, #276ABE)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          },
    },
    componentName: "SectionCta",
  });
};
