/**
 * Function to check that imageUrl is from cdnUrl will need to explicit convert to String.
 * Similar Problem
 * https://stackoverflow.com/questions/38813040/startswith-is-not-a-function-getting-called-for-no-reason
 */
export const isImageFromOptimizingCdn = (
  imageUrl: string,
  cdnBaseUrl: string,
) => {
  return cdnBaseUrl && imageUrl && String(imageUrl).startsWith(cdnBaseUrl);
};
