import React from "react";
import Drawer from "@mui/material/Drawer";
import { IMobileModalProps } from "../Modal.types";
import { classNames } from "../../../../utils/common/styling";
import { styles } from "./MobileModal.styles";

const MobileModal = ({
  children,
  isOpen,
  onClose,
  classes,
  overrideClasses,
  keepMounted,
}: IMobileModalProps) => {
  const { root: rootClass } = classes || {};
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClose={onClose}
      classes={{
        root: classNames(rootClass, styles.root),
        ...overrideClasses,
      }}
      keepMounted={keepMounted}
    >
      {children}
    </Drawer>
  );
};

export default MobileModal;
