import { checkIsStringEmpty } from "../../../../../utils/common/dataTypes/string";
import {
  GET_WISTIA_VIDEO_ID_REGEX,
  VERIFY_WISITA_LINK_REGEX,
  VERIFY_YOUTUBE_LINK_REGEX,
} from "../constants/VideoUploader.constants";

export const isValidYoutubeUrl = (url: string) => {
  if (checkIsStringEmpty(url)) return false;
  if (!checkIsStringEmpty(url) && VERIFY_YOUTUBE_LINK_REGEX.test(url))
    return true;
  return false;
};

export const isWistiaVideoLink = (url: string) => {
  return VERIFY_WISITA_LINK_REGEX.test(url);
};

export const getWistiaVideoId = (url: string) => {
  // Attempt to match the URL with the regex
  const match = url.match(GET_WISTIA_VIDEO_ID_REGEX);

  // If a match is found and video ID is captured
  const [, videoId] = match || []; // Destructure match, or use empty array as fallback

  return videoId || null;
};
