import { ICreatorTheme } from "../theme.interface";

export const DEFAULT_CREATOR_THEME: ICreatorTheme = {
  colors: {
    color1: "",
    color2: "",
    color3: "",
    color4: "",
    color5: "",
  },
  font: {
    slug: "",
    title: "",
    url: "",
  },
};
