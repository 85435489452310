export const SCHEDULE_TYPES_IDS = {
  ONE_ON_ONE_APPOINTMENT: 0,
  ONE_ON_ONE_CLASS: 1,
  GROUP_CLASS: 2,
  WORKSHOP: 3,
  QUICK_PAYMENT_PAGE: 4,
  CONTENT: 5,
  MERCHANDISE: 6,
  ROLLING_CLASS: 7,
  TELEGRAM: 8,
  WEBINAR: 9,
  WA_COMMUNITY: 10,
  BRANDED_COMMUNITY: 11,
};
