// used in:
//   - exly-creator-app; answers_in_comments;

import { COMMENT_SOURCE } from "../../../../Common/modules/Comments/constants/Comments";

export const EXAM_RESPONSE_COMMENT_VISIBILITY = {
  HIDDEN: 1,
  ATTEMPTED: 2,
  ALL: 3,
};

export const COURSE_COMMENT_TYPE = {
  TEXT: 1,
  IMAGE: 2,
  HTML: 3,
};

/**
 * @deprecated
 */
export const RECORDED_COMMENT_SOURCE = {
  USER_INPUT: COMMENT_SOURCE.USER_INPUT,
  EXAM_SUBMISSION: COMMENT_SOURCE.RC_EXAM_SUBMISSION,
};

export const TABS = {
  DISCUSSION: RECORDED_COMMENT_SOURCE.USER_INPUT,
  UPDATE_FROM_PEERS: RECORDED_COMMENT_SOURCE.EXAM_SUBMISSION,
};

export const TAB_OPTIONS = [
  {
    label: "Discussion",
    value: TABS.DISCUSSION,
  },
  {
    label: "Updates from peers",
    value: TABS.UPDATE_FROM_PEERS,
  },
];

export const COMMENT_STATUS = {
  SHOWN: 1,
  HIDDEN: 2,
};
