import React, { FC } from "react";

import Profile from "../Profile/Profile";

import { classNames } from "../../../../utils/common/styling";

import { ISidebarWrapper } from "./ISidebarWrapper";
import { styles } from "./SidebarWrapper.styles";

import { DEFAULT_LISTING_IMG_SRC } from "../../../../assets/listings";
import { DEFAULT_CREATOR_THEME } from "../../../../features/Common/modules/Theme/constants/Theme.constants";
import { CreatorThemeContext } from "../../../../features/Common/modules/Theme/CreatorThemeContext";

const SidebarWrapper: FC<ISidebarWrapper.IProps> = ({
  children,
  showDividers: hasDividers = true,
  profileProps,

  className,
  bodyClassName,
  configClassName,
  dividerClassName,
  coverImageClassName,

  theme = DEFAULT_CREATOR_THEME,
}) => {
  const { coverImage } = profileProps || {};

  const totalChildrens = Array.isArray(children) ? children.length : 1;

  return (
    <CreatorThemeContext.Provider value={theme}>
      <div className={classNames(styles.root, className)}>
        <img
          src={coverImage || DEFAULT_LISTING_IMG_SRC}
          alt="cover"
          className={classNames(styles.cover, coverImageClassName)}
        />

        <div className={classNames(styles.body, bodyClassName)}>
          <Profile {...profileProps} />

          <div className={classNames(styles.config, configClassName)}>
            {hasDividers
              ? React.Children.map(
                  children,
                  (children: React.ReactNode & { key: string }, index) => (
                    <React.Fragment key={children.key}>
                      {children}
                      {/* TODO: simplify this
                      much simpler way to insert a spearator was to use css psuedo selectors nth child and last child */}
                      {index < totalChildrens - 1 && (
                        <hr
                          className={classNames(
                            styles.divider,
                            dividerClassName,
                          )}
                        />
                      )}
                    </React.Fragment>
                  ),
                )
              : children}
          </div>
        </div>
      </div>
    </CreatorThemeContext.Provider>
  );
};

export default SidebarWrapper;
