import React, { FC } from "react";

import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";

import { classNames } from "../../../../utils/common/styling";

import { IProfile } from "./IProfile";
import { styles } from "./Profile.styles";

const Profile: FC<IProfile.IProps> = ({
  participants,
  photo,
  username,
  onClickPhoto,

  className,
  profilePicWrapperClassName,
  profilePicClassName,
  contentClassName,
  usernameClassName,
  participantsClassName,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div
        className={classNames(
          styles.profilePicWrapper,
          profilePicWrapperClassName,
        )}
        onClick={onClickPhoto}
      >
        <img
          src={photo}
          alt=""
          className={classNames(styles.profilePic, profilePicClassName)}
        />
      </div>

      <div className={classNames(styles.content, contentClassName)}>
        <h1 className={classNames(styles.title, usernameClassName)}>
          {username}
        </h1>
        <div className={classNames(styles.participants, participantsClassName)}>
          <PeopleIcon />
          {participants}
        </div>
      </div>
    </div>
  );
};

export default Profile;
