import AmazonFavicon from "../../../../../../../assets/images/site_icons/amazon.png";
import AmazonLogo from "../../../../../../../assets/images/site_logo/amazon.jpeg";
import FlipkartFavicon from "../../../../../../../assets/images/site_icons/flipkart.png";
import HomecentreLogo from "../../../../../../../assets/images/site_logo/homecentre.jpeg";
import LifestyleFavicon from "../../../../../../../assets/images/site_icons/lifestylestores.webp";
import MeeshoLogo from "../../../../../../../assets/images/site_logo/meesho.png";
import MyntraFavicon from "../../../../../../../assets/images/site_icons/myntra.png";

const SITE_KEYS = {
  amazon: "amazon",
  flipkart: "flipkart",
  homecentre: "homecentre",
  lifestylestores: "lifestylestores",
  meesho: "meesho",
  myntra: "myntra",
  snapdeal: "snapdeal",
};

const HARD_CODED_LINK_PREVIEW_DATA: {
  [siteKey: string]: {
    title: string;
    description: string;
    domain: string;
    favicon: string;
    images: string[];
  };
} = {
  [SITE_KEYS.amazon]: {
    title: "Amazon.in",
    description:
      "Online Shopping site in India: Shop Online for Mobiles, Books, Watches, Shoes and More",
    domain: "amazon.in",
    favicon: AmazonFavicon,
    images: [AmazonLogo],
  },
  [SITE_KEYS.flipkart]: {
    title: "Flipkart",
    description:
      "India's biggest online store for Mobiles, Fashion (Clothes/Shoes), Electronics, Home Appliances, Books, Home, Furniture, Grocery, Jewelry, Sporting goods, Beauty & Personal Care and more! Find the largest selection from all brands at the lowest prices in India. Payment options - COD, EMI, Credit card, Debit card &amp; more.",
    domain: "flipkart.com",
    favicon: FlipkartFavicon,
    images: [FlipkartFavicon],
  },
  [SITE_KEYS.homecentre]: {
    title: "Home Centre: Online Shopping Store for Furniture, Decor, & More",
    description:
      "Buy Furniture, Decor & More at Home Centre. Explore ⭐ Bedroom Furniture, Home Decor ⭐ Kitchen Accessories & more. Shop now at Best Prices!",
    domain: "homecentre.in",
    favicon: HomecentreLogo,
    images: [HomecentreLogo],
  },
  [SITE_KEYS.lifestylestores]: {
    title: "Online Shopping for Men, Women & Kids in India | Lifestyle Stores",
    description:
      "Online Shopping India - Online Shopping Site for Men, Women, and Kids. Buy Apparel, Footwear, Bags, Fashion Accessories and more on Lifestyle Stores ",
    domain: "lifestylestores.com",
    favicon: LifestyleFavicon,
    images: [LifestyleFavicon],
  },
  [SITE_KEYS.meesho]: {
    title:
      "Online Shopping Site for Fashion, Electronics, Home & More | Meesho",
    description:
      "Online Shopping & Reselling site in India - Buy & Sell best quality Fashion, Electronics, Home & Kitchen products at lowest prices.",
    domain: "meesho.com",
    favicon: MeeshoLogo,
    images: [MeeshoLogo],
  },
  [SITE_KEYS.myntra]: {
    title: "Myntra.com",
    description:
      "Online Shopping India - Shop Online for Branded Shoes, Clothing & Accessories in India",
    domain: "myntra.com",
    favicon: MyntraFavicon,
    images: [MyntraFavicon],
  },
};

const SITE_REGEX = {
  [SITE_KEYS.amazon]: [
    /(^|\.)amazon\.(com|in|co\.uk|ca|de|fr|it|es|com\.au|com\.mx|com\.br|nl|ae|sa|sg|jp|cn|se|tr|pl|dk|fi|no|co|com\.tr|com\.eg|com\.sa)$/,
    /(^|\.)amzn\.(in|com|co\.uk|de|es|fr|it|com\.au|com\.mx|com\.br|nl|ae|sa|sg|jp|cn|se|tr|pl|dk|fi|no|co|com\.tr|com\.eg|com\.sa)$/,
  ],
  [SITE_KEYS.flipkart]: [/(^|\.)flipkart\.com$/],
  [SITE_KEYS.meesho]: [/(^|\.)meesho\.com$/],
  [SITE_KEYS.lifestylestores]: [/(^|\.)lifestylestores\.com$/],
  [SITE_KEYS.myntra]: [/(^|\.)myntra\.com$/],
  [SITE_KEYS.homecentre]: [/(^|\.)homecentre\.in$/],
  [SITE_KEYS.snapdeal]: [/(^|\.)snapdeal\.com$/],
};

export const SNAPDEAL_BROKEN_PREVIEW_IMAGE_REGEX = /(^|\.)snapdeal\.comhttps/;

export const SNAPDEAL_PREVIEW_BROKEN_IMAGE_LINK_PREFIX =
  "https://www.snapdeal.comhttps:/";

export const SITES_REGEX_DATA_MAPPING = {
  [SITE_KEYS.amazon]: {
    regexes: SITE_REGEX[SITE_KEYS.amazon],
    data: HARD_CODED_LINK_PREVIEW_DATA[SITE_KEYS.amazon],
  },
  [SITE_KEYS.flipkart]: {
    regexes: SITE_REGEX[SITE_KEYS.flipkart],
    data: HARD_CODED_LINK_PREVIEW_DATA[SITE_KEYS.flipkart],
  },
  [SITE_KEYS.homecentre]: {
    regexes: SITE_REGEX[SITE_KEYS.homecentre],
    data: HARD_CODED_LINK_PREVIEW_DATA[SITE_KEYS.homecentre],
  },
  [SITE_KEYS.lifestylestores]: {
    regexes: SITE_REGEX[SITE_KEYS.lifestylestores],
    data: HARD_CODED_LINK_PREVIEW_DATA[SITE_KEYS.lifestylestores],
  },
  [SITE_KEYS.meesho]: {
    regexes: SITE_REGEX[SITE_KEYS.meesho],
    data: HARD_CODED_LINK_PREVIEW_DATA[SITE_KEYS.meesho],
  },
  [SITE_KEYS.myntra]: {
    regexes: SITE_REGEX[SITE_KEYS.myntra],
    data: HARD_CODED_LINK_PREVIEW_DATA[SITE_KEYS.myntra],
  },
};
