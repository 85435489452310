import { getStylesObjFromCss } from "../../../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "8px",
      cursor: "pointer",
      padding: "6px",
      borderRadius: "8px",
      transition: "all 0.1s",
      "&:hover": { backgroundColor: "#f0f2f5" },
    },
    profileContainer: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      color: "#272522",
      fontSize: "14px",
    },
    ctaContainer: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      "& button": { padding: "0" },
      "& svg": { color: "#d0d0d0", fontSize: "18px" },
    },
  },
  componentName: "MembersPanelMember",
});
