import { getStylesObjFromCss } from "../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      padding: "12px 24px 16px 24px",
      color: "rgba(39, 37, 34, 0.5)",
      fontSize: "14px",
      fontWeight: 500,
      "& div": {
        borderRadius: "50%",
        backgroundColor: "#13cf32",
        height: "8px",
        width: "8px",
      },
    },
  },
  componentName: "MembersPanel",
});
