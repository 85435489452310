import { getStylesObjFromCss } from "../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    wrapper: {
      display: "inline-block",
    },
    popoverPaper: {
      boxShadow: "0px 4px 4px rgba(14, 13, 12, 0.16)",
      borderRadius: "5px",
    },
    drawerPaper: {
      borderRadius: "5px 5px 0 0",
    },
  },
  componentName: "Popover",
});
