import React, { forwardRef } from "react";
import { styles } from "./ModalContent.styles";
import { classNames } from "../../../../utils/common/styling";
import { IModalContentProps } from "./ModalContent.types";

const ModalContent = forwardRef<HTMLDivElement, IModalContentProps>(
  ({ children, className }: IModalContentProps, ref) => (
    <div tabIndex={-1} ref={ref} className={classNames(styles.root, className)}>
      {children}
    </div>
  ),
);

ModalContent.displayName = "ModalContent";

export default ModalContent;
