import React from "react";
import Modal from "../../common/Modal/Modal";
import ModalContent from "../../common/Modal/ModalContent/ModalContent";
import ModalBody from "../../common/Modal/ModalBody/ModalBody";
import ModalFooter from "../../common/Modal/ModalFooter/ModalFooter";
import ModalHeader from "../../common/Modal/ModalHeader/ModalHeader";
import { IEditPostModalProps } from "./EditPostModal.types";
import { styles } from "./EditPostModal.styles";
import { useIsDesktop } from "../../../utils/common/screen";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PostTypeSelector from "../PostTypeSelector/PostTypeSelector";
import LazyLoadedPostImage from "../PostImage/LazyLoadedPostImage";
import LazyLoadedPostDoc from "../PostDoc/LazyLoadedPostDoc";
import LazyLoadedPostVideo from "../PostVideo/LazyLoadedPostVideo";
import LazyLoadedPostOffering from "../PostOffering/LazyLoadedPostOffering";
import TextAreaWithAutoHeight from "../../common/FormInputs/TextArea/TextAreaWithAutoHeight";
import { DEFAULT_USER_PROFILE_IMG_SRC } from "../../../assets/user";

const EditPostModal = ({
  // generic modal component props
  isOpen,
  onClose,

  // other EditPostModalProps
  title = "Edit post",
  authorImgSrc = DEFAULT_USER_PROFILE_IMG_SRC,
  authorName,
  postContentDefaultValue,
  postContentTextAreaRef,
  SaveButton = () => null,
  Loader,
  makeModalInvisible,
  CustomTextEditorComponent,

  // post type generic props
  postTypeSelectorProps,
  postMediaUploading,
  postMediaIsAdded,

  // post type media props
  postImageProps,
  postDocProps,
  postVideoProps,
  postOfferingProps,

  keepMounted,

  // patch fix for RTE
  // bug: RTE is not working properly on iOS inside MUI v5 Modal: unable to add space in editor
  // solution: inject Modal component from parent component. injecting MUI v4 modal from creator tool
  customModalComponent,
}: IEditPostModalProps) => {
  const isDesktop = useIsDesktop();

  const PostTypeSelectorComponent = (
    <div className={styles.postTypeSelectorContainer}>
      <PostTypeSelector {...postTypeSelectorProps} />
    </div>
  );

  const ModalComponent = customModalComponent || Modal;
  const TextEditorComponent =
    CustomTextEditorComponent || TextAreaWithAutoHeight;

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      classes={{ root: makeModalInvisible ? styles.invisible : "" }}
      keepMounted={keepMounted}
    >
      <ModalContent className={styles.contentRoot}>
        {isDesktop ? (
          <ModalHeader onClose={onClose} hideCloseIcon={!isDesktop}>
            {title}
          </ModalHeader>
        ) : (
          <div className={styles.headerRoot}>
            <div className={styles.titleContainer}>
              <CloseRoundedIcon className={styles.close} onClick={onClose} />
              {title}
            </div>
            <SaveButton />
          </div>
        )}
        <ModalBody className={styles.body}>
          <div className={styles.postCreatorDetailsContainer}>
            <img
              src={authorImgSrc}
              width={46}
              height={46}
              className={styles.authorImg}
              alt={authorName}
            />
            <span className={styles.authorName}>{authorName}</span>
          </div>
          <TextEditorComponent
            autoFocus
            placeholder="What would you like to talk about?"
            inputRef={postContentTextAreaRef}
            defaultValue={postContentDefaultValue}
            rows={1}
          />
          {postMediaUploading ? <Loader /> : null}
          {postMediaIsAdded ? (
            <>
              <div className={styles.mediaSeparator} />
              <LazyLoadedPostImage {...postImageProps} />
              <LazyLoadedPostDoc {...postDocProps} />
              <LazyLoadedPostVideo {...postVideoProps} />
              <LazyLoadedPostOffering
                {...postOfferingProps}
                classes={{
                  container: styles.offeringContainer,
                  image: styles.offeringImage,
                }}
              />
            </>
          ) : null}
        </ModalBody>
        {isDesktop ? (
          <ModalFooter className={styles.footer}>
            {PostTypeSelectorComponent}
            <SaveButton />
          </ModalFooter>
        ) : (
          PostTypeSelectorComponent
        )}
      </ModalContent>
    </ModalComponent>
  );
};

export default EditPostModal;
