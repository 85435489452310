import React from "react";
import { getThemedStyles, styles } from "./MoreButtonAndMenu.styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { classNames } from "../../../../../utils/common/styling";
import { IMoreButtonAndMenuProps } from "./MoreButtonAndMenu.types";
import Popover from "../../../../common/Popover/Popover";
import { COMMENT_STATUS } from "../../../../../features/Offerings/modules/RecordedContent/CommentSection/CommentSection.data";
import { useCreatorThemeContext } from "../../../../../features/Common/modules/Theme/CreatorThemeContext";

const MoreButtonAndMenu = ({
  MoreButtonComponent,
  canEdit,
  onEditClick,
  canDelete,
  onDeleteClick,
  currentCommentStatus,
  canHideUnhide,
  onHideUnhideClick,
}: IMoreButtonAndMenuProps) => {
  if (!canEdit && !canDelete && !canHideUnhide) return null;
  const theme = useCreatorThemeContext();
  const themedStyles = getThemedStyles({ theme });

  const isCurrentCommentHidden = currentCommentStatus == COMMENT_STATUS.HIDDEN;

  return (
    <Popover
      AnchorComponent={({ openPopover }) => (
        <MoreButtonComponent
          className={styles.moreButton}
          onClick={openPopover}
          fontSize="small"
        />
      )}
      PopoverComponent={({ closePopover }) => (
        <div className={styles.moreMenuContainer}>
          {canEdit ? (
            <div
              className={styles.moreMenuItem}
              onClick={(e) => {
                onEditClick();
                closePopover(e);
              }}
            >
              <EditOutlinedIcon
                fontSize="small"
                className={classNames(themedStyles.editIcon, styles.editIcon)}
              />
              Edit
            </div>
          ) : null}
          {canHideUnhide && (
            <div
              className={styles.moreMenuItem}
              onClick={(e) => {
                if (onHideUnhideClick)
                  onHideUnhideClick({
                    status: isCurrentCommentHidden
                      ? COMMENT_STATUS.SHOWN
                      : COMMENT_STATUS.HIDDEN,
                  });
                closePopover(e);
              }}
            >
              {isCurrentCommentHidden ? (
                <>
                  <VisibilityOutlinedIcon
                    fontSize="small"
                    className={styles.hideUnhideIcon}
                  />
                  Unhide
                </>
              ) : (
                <>
                  <VisibilityOffOutlinedIcon
                    fontSize="small"
                    className={styles.hideUnhideIcon}
                  />
                  Hide
                </>
              )}
            </div>
          )}
          {canDelete ? (
            <div
              className={styles.moreMenuItem}
              onClick={(e) => {
                onDeleteClick();
                closePopover(e);
              }}
            >
              <svg
                width={16}
                height={16}
                className={styles.deleteIcon}
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.83203 15.5C2.3737 15.5 1.98148 15.3369 1.65536 15.0108C1.3287 14.6842 1.16536 14.2917 1.16536 13.8333V3C0.929253 3 0.731198 2.92028 0.571198 2.76083C0.411754 2.60083 0.332031 2.40278 0.332031 2.16667C0.332031 1.93056 0.411754 1.7325 0.571198 1.5725C0.731198 1.41306 0.929253 1.33333 1.16536 1.33333H4.4987C4.4987 1.09722 4.5787 0.899167 4.7387 0.739167C4.89814 0.579722 5.09592 0.5 5.33203 0.5H8.66536C8.90148 0.5 9.09953 0.579722 9.25953 0.739167C9.41898 0.899167 9.4987 1.09722 9.4987 1.33333H12.832C13.0681 1.33333 13.2659 1.41306 13.4254 1.5725C13.5854 1.7325 13.6654 1.93056 13.6654 2.16667C13.6654 2.40278 13.5854 2.60083 13.4254 2.76083C13.2659 2.92028 13.0681 3 12.832 3V13.8333C12.832 14.2917 12.669 14.6842 12.3429 15.0108C12.0162 15.3369 11.6237 15.5 11.1654 15.5H2.83203ZM2.83203 3V13.8333H11.1654V3H2.83203ZM4.4987 11.3333C4.4987 11.5694 4.5787 11.7672 4.7387 11.9267C4.89814 12.0867 5.09592 12.1667 5.33203 12.1667C5.56814 12.1667 5.7662 12.0867 5.9262 11.9267C6.08564 11.7672 6.16536 11.5694 6.16536 11.3333V5.5C6.16536 5.26389 6.08564 5.06583 5.9262 4.90583C5.7662 4.74639 5.56814 4.66667 5.33203 4.66667C5.09592 4.66667 4.89814 4.74639 4.7387 4.90583C4.5787 5.06583 4.4987 5.26389 4.4987 5.5V11.3333ZM7.83203 11.3333C7.83203 11.5694 7.91203 11.7672 8.07203 11.9267C8.23148 12.0867 8.42925 12.1667 8.66536 12.1667C8.90148 12.1667 9.09953 12.0867 9.25953 11.9267C9.41898 11.7672 9.4987 11.5694 9.4987 11.3333V5.5C9.4987 5.26389 9.41898 5.06583 9.25953 4.90583C9.09953 4.74639 8.90148 4.66667 8.66536 4.66667C8.42925 4.66667 8.23148 4.74639 8.07203 4.90583C7.91203 5.06583 7.83203 5.26389 7.83203 5.5V11.3333Z"
                  fill="#BF2600"
                  fillOpacity="0.85"
                />
              </svg>
              Delete
            </div>
          ) : null}
        </div>
      )}
      popoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
    />
  );
};

export default MoreButtonAndMenu;
