import React from "react";
import DesktopModal from "./DesktopModal/DesktopModal";
import MobileModal from "./MobileModal/MobileModal";
import { useIsDesktop } from "../../../utils/common/screen";
import { IModalProps } from "./Modal.types";

/**
 * the children should be an html element that accepts ref. `ModalContent` handles that itself
 */
const Modal = ({
  children,
  onClose: onCloseProp = () => {},
  closeOnEscapeKeyDown,
  closeOnBackdropClick,
  forceDesktopDesign,
  ...rest
}: IModalProps) => {
  const isDesktop = useIsDesktop();

  const onClose: IModalProps["onClose"] = (e, reason) => {
    if (reason === "escapeKeyDown" && !closeOnEscapeKeyDown) return;
    if (reason === "backdropClick" && !closeOnBackdropClick) return;
    onCloseProp(e);
  };

  const ModalComponent =
    isDesktop || forceDesktopDesign ? DesktopModal : MobileModal;

  return (
    <ModalComponent onClose={onClose} {...rest}>
      {children}
    </ModalComponent>
  );
};

export default Modal;
