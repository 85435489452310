import { getStylesObjFromCss } from "../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    root: {},

    profilePicWrapper: {
      padding: "6px",
      borderRadius: "50%",
      background: "white",
      position: "absolute",
      top: "-40px",
      left: "13px",
    },
    profilePic: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
    },

    content: {
      margin: "0 16px 0 18px",
      paddingTop: "62px",
    },
    title: {
      color: "#272522",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "normal",
      margin: 0,
    },
    participants: {
      display: "flex",
      alignItems: "center",
      marginTop: "15.05px",
      gap: "4px",
      "& svg": {
        color: "#272522",
        opacity: 0.3,
        fontSize: "22px",
      },
      color: "rgba(39, 37, 34, 0.60)",
      fontSize: "16px",
      fontStyle: "normal",
      lineHeight: "normal",
    },
  },
  componentName: "Profile",
});
