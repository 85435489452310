import { getStylesObjFromCss } from "../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    postTypeOption: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    "postTypeOption-disabled": {
      opacity: "0.5",
      cursor: "not-allowed",
    },
    postTypeOptionLabel: {
      fontWeight: "500",
      fontSize: "14px",
      color: "#6f6d6a",
      marginLeft: "8px",
    },
  },
  componentName: "PostTypeSelector",
});
