import { useEffect, useState } from "react";
import { useToggleState } from "../../../../../../utils/common/dataTypes/boolean";
import { ILinkPreviewMetadata } from "./LinkPreview.interfaces";
import { fetchWebpageMetadata } from "./utils/fetchWebpageMetadata";
import { getUnsupportedSiteLinkPreviewData } from "../../../../../Offerings/modules/BrandedCommunity/components/LinkPreview/utils/LinkPreview.utils";

const useLinkPreview = ({ url, apiKey }: { url?: string; apiKey?: string }) => {
  const [previewData, setPreviewData] = useState<ILinkPreviewMetadata | null>(
    null,
  );
  const [isLoading, startLoading, stopLoading] = useToggleState(false);

  const fetchData = async () => {
    if (!url) return;

    const unsupportedLinkData = getUnsupportedSiteLinkPreviewData(url);

    // if url belongs to an unsupported site, set preview data and return
    if (unsupportedLinkData) {
      setPreviewData(unsupportedLinkData);
      return;
    }

    startLoading();

    fetchWebpageMetadata({
      url,
      apiKey,
      onSuccess: setPreviewData,
      onFinally: stopLoading,
    });
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return { previewData, isLoading };
};

export default useLinkPreview;
