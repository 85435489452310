import React, { FC } from "react";

import { IStatus } from "./IStatus";
import { styles } from "./Status.styles";
import { classNames } from "../../../../../utils/common/styling";

const Status: FC<IStatus.IProps> = ({ className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      Online now <div />
    </div>
  );
};

export default Status;
